// eslint-disable-next-line object-curly-newline
import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Inicio',
    icon: mdiHomeOutline,
    to: 'home-inicio',

    // children: [
    //   {
    //     icon: mdiMonitorDashboard,
    //     title: 'CRM',
    //     to: 'dashboard-crm',
    //   },
    //   {
    //     icon: mdiChartTimelineVariant,
    //     title: 'Analytics',
    //     to: 'dashboard-analytics',
    //   },
    //   {
    //     icon: mdiCartOutline,
    //     title: 'eCommerce',
    //     to: 'dashboard-eCommerce',
    //   },
    // ],
  },
]
