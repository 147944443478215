import { mdiAccountSupervisorCircle, mdiClipboardText, mdiClockFast, mdiHandshake } from '@mdi/js'

export default [
  {
    icon: mdiHandshake,
    title: 'Venta',
    to: 'ventas',
  },
  {
    icon: mdiAccountSupervisorCircle,
    title: 'Pólizas',
    to: 'poliza',
  },
  {
    icon: mdiClockFast,
    title: 'Curso solicitud',
    to: 'cursoSolicitud',
  },
  {
    icon: mdiClipboardText,
    title: 'Informes',
    to: 'informes',
  },
]
