import { mdiAccountSupervisorCircl, mdiHandshake } from '@mdi/js'

export default [
  {
    icon: mdiHandshake,
    title: 'Venta',
    to: 'ventas',
  },
  {
    icon: mdiAccountSupervisorCircl,
    title: 'Pólizas',
    to: 'poliza',
  },
]
