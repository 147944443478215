const formTable = [
  {
    path: '/ventas',
    name: 'ventas',
    component: () => import('@/views/forms/ventas/ventas.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/polizas',
    name: 'poliza',
    component: () => import('@/views/forms/polizas/poliza.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/polizas/:rut',
    name: 'poliza_id',
    component: () => import('@/views/forms/polizas/poliza.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/polizas/ver-cliente/:rut',
    name: 'verCliente',
    component: () => import('@/views/forms/polizas/demos/ver-cliente.vue'),

    meta: {
      layout: 'content',
    },
  },
  {
    path: '/polizas/detalle-cliente/:rut/:propuesta',
    name: 'detalleCliente',
    component: () => import('@/views/forms/polizas/demos/detalle-cliente.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/polizas/cancelar/:rut',
    name: 'cancelarCliente',
    component: () => import('@/views/forms/polizas/demos/cancelar.vue'),

    meta: {
      layout: 'content',
    },
  },
  {
    path: '/curso-solicitud',
    name: 'cursoSolicitud',
    component: () => import('@/views/forms/curso-solicitud/curso-solicitud.vue'),

    meta: {
      layout: 'content',
    },
  },
  {
    path: '/informes',
    name: 'informes',
    component: () => import('@/views/forms/informes/informes.vue'),

    meta: {
      layout: 'content',
    },
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('@/views/perfil.vue'),

    meta: {
      layout: 'content',
    },
  },

  {
    path: '/cambiar-clave',
    name: 'cambiarClave',
    component: () => import('@/views/cambiarclave.vue'),

    meta: {
      layout: 'content',
    },
  },

  {
    path: '/solicitud-token',
    name: 'solicitudToken',
    component: () => import('@/views/formSolicitud-token.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

export default formTable
