import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Inicio',
    icon: mdiHomeOutline,
    to: 'home-inicio',

    // children: [
    //   {
    //     title: 'Home',
    //     to: 'dashboard-crm',
    //   },
    // {
    //   title: 'Analytics',
    //   to: 'dashboard-analytics',
    // },
    // {
    //   title: 'eCommerce',
    //   to: 'dashboard-eCommerce',
    // },
    // ],
  },
]
